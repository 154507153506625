export default {
  data() {
    return {
      cpvTypes: {
        hy: { 1: 'ապրանք', 2: 'ծառայություն', 3: 'աշխատանք' },
        ru: { 1: 'товаров', 2: 'сервисов', 3: 'работ' },
      },
    }
  },
}
